import "waypoints/lib/noframework.waypoints";

export default class Reveal {
  constructor(elements) {
    Array.from(elements).forEach((element) => {
      this.hide(element);

      new window.Waypoint({
        element: element,
        handler: () => {
          this.show(element);
        },
        offset: "90%",
      });
    });
  }

  hide(element) {
    element.style.opacity = 0;
    element.style.transform = "translateY(100px)";
    element.style.transition = "all .5s ease";
  }

  show(element) {
    element.style.opacity = 1;
    element.style.transform = "translateY(0)";
  }
}
