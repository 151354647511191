import Swiper from "swiper/bundle";
import Reveal from "./app/reveal";
import Spacing from "./app/spacing";
import Parallax from "./app/parallax";
import scrollToTop from "./app/scrollToTop.js";
import generateStyles from "./app/generateStyles";
import VerticalOffset from "./app/verticalOffset";
import Navbar, { toggleNavbar } from "./app/navbar";
import { breakpoints } from "./app/settings";
import SwiperPagination from "./app/swiperPagination";

export const main = () => {
  /**
   * First we'll calculate the vertical offset for the given node
   * list by their nodes individually data attributes.
   */

  new VerticalOffset(Array.from(document.querySelectorAll(".vertical-offset")));

  /**
   * Next we'll dynamically calculate the vertical spacing for the given
   * node list and set the margin by their elements data attributes.
   */

  new Spacing(Array.from(document.querySelectorAll(".vertical-spacing")));

  /**
   * We'll create a new navbar instance which itself setup a menu instance to
   * easily handle the different navigation states without duplications.
   */
  const navbarElement = document.querySelector(".navbar");
  const navbar = navbarElement && new Navbar(document.querySelector(".navbar"));

  /**
   * Next we'll generate extended stylesheets on runtime.
   */
  const customColors = {
    "dak-gesundheit": "#FF7C00",
    hansemerkur: "#005e52",
    eppendorf: "#0035ad",
    gdv: "#8a1538",
    generali: "#c5281c",
    "hamburg-de": "#003063",
    haspa: "#FF0000",
    hc24: "#00afbf",
    swp: "#006a4d",
    lebensbaum: "#009549",
    "lebensbaum-sustainability-report": "#009549",
    "techniker-krankenkasse": "#008dc9",
    bgw: "#004994",
    cosmosdirekt: "#090099",
    "dkv-mobility": "#F18400",
  };
  generateStyles(customColors);

  /**
   * Here we'll initialze a new reveal instance. A reveal dynamically transforms
   * all elements to their default position if they have reached the viewport.
   */

  new Reveal(document.querySelectorAll(".reveal"));

  /**
   * Next we'll require Swiper a slider dependency with hardware accelerated transitions and native behavior.
   * @see  https://idangero.us/swiper/api/
   */

  Array.from(document.querySelectorAll(".swiper-container")).forEach(
    (element) => {
      let params = {
        slidesPerView: "auto",
        loop: element.dataset.loop ? true : false,
        centeredSlides: element.dataset.center ? true : false,
        autoplay: element.dataset.autoplay
          ? { delay: element.dataset.autoplay }
          : false,
        simulateTouch: element.dataset.autoplay ? false : true,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          renderBullet: element.dataset.autoplay
            ? SwiperPagination.autoplayBullet()
            : SwiperPagination.defaultBullet(),
        },
      };

      let slider = new Swiper(element, params);

      if (element.dataset.autoplay) {
        new SwiperPagination(slider);
      }

      let init = () => {
        if (window.innerWidth < breakpoints.md) {
          slider.destroy(false, true);
          slider.el.classList.add("destroyed");
        } else {
          if (slider.initialized) return;

          slider.init();
          slider.pagination.init();
          slider.el.classList.remove("destroyed");
        }
      };

      if (element.dataset.destroy) {
        window.addEventListener("resize", () => {
          init();
        });

        init();
      }
    }
  );

  /**
   * Next we'll initialze a new parallax instance.
   */

  const parallaxTranslate = new Parallax(
    document.querySelector(".parallax-translate"),
    "translate"
  );

  const parallaxBackgrounds = Array.from(
    document.querySelectorAll(".parallax-background")
  ).map((item) => {
    return new Parallax(item, "background");
  });

  /**
   * Require Scroll To Top function
   */
  const backToTop = document.querySelector(".back-to-top");
  backToTop &&
    backToTop.addEventListener("click", () => {
      scrollToTop(300, "easeOutQuad");
    });

  /**
   * Bind methods to the animation frame request.
   * @return {void}
   */
  const animations = () => {
    /**
     * Toggle the navbar based on the current scroll position.
     * @param {Navbar}  navbar
     */
    navbarElement && toggleNavbar(navbar);

    /**
     * Update the positions of the parallax background.
     */
    parallaxTranslate.animate();
    parallaxBackgrounds.forEach((parallaxBackground) =>
      parallaxBackground.animate()
    );

    window.requestAnimationFrame(animations);
  };

  window.requestAnimationFrame(animations);
};
