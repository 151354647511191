export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const colors = {
  white: "#FFFFFF",
  black: "#303030",
  mondayGreen: "#007067",
  mondaySkyblue: "#0092CE",
  mondayYello: "#F0E502",
};

export const hasClass = (element, className) => {
  return element.classList.contains(className);
};

export const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};
