import { breakpoints } from "./settings.js";

export default class Spacing {
  constructor(elements) {
    this.minWidth = breakpoints.lg;
    this.elements = elements;

    this.handle(elements).listen();
  }

  handle(elements) {
    elements.forEach((element) => {
      element.style.cssText = "";
      this.setSpacing(element);
    });

    return this;
  }

  listen() {
    window.addEventListener("resize", () => {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => this.handle(this.elements), 100);
    });
  }

  setSpacing(element) {
    if (element.dataset.spacingTop) {
      element.style.paddingTop = this.getPaddingTop(element);
    }

    if (element.dataset.spacingBottom) {
      element.style.paddingBottom = this.getPaddingBottom(element);
    }
  }

  getPaddingTop(element) {
    if (window.innerWidth < this.minWidth) {
      return "60px";
    }

    return this.getTargetOffset(
      document.getElementById(element.dataset.spacingTop)
    );
  }

  getPaddingBottom(element) {
    if (window.innerWidth < this.minWidth) {
      return "60px";
    }

    return this.getTargetOffset(
      document.getElementById(element.dataset.spacingBottom)
    );
  }

  getTargetOffset(element) {
    if (!(element && element.dataset && element.dataset.offset)) return;

    let matches = element.dataset.offset.match(/^(\d+(?:\.\d+)?)(.*)$/);

    if (matches[2] === "px") {
      return matches[1] + "px";
    }

    if (matches[2] === "%") {
      return (element.clientHeight * matches[1]) / 100 + "px";
    }
  }
}
