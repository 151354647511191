import MarkdownImage from "../../../../src/components/markdownImage";
import properiesFilteredUrl from "../../../../content/news/2019/11-28_monday-search-detailsuche-coremedia-studio/de_properties_filtered.png";
import comparatorsStringUrl from "../../../../content/news/2019/11-28_monday-search-detailsuche-coremedia-studio/de_comparators_string.png";
import valueEditorUserUrl from "../../../../content/news/2019/11-28_monday-search-detailsuche-coremedia-studio/de_valueeditor_user.png";
import valueEditorDateUrl from "../../../../content/news/2019/11-28_monday-search-detailsuche-coremedia-studio/de_valueeditor_date.png";
import * as React from 'react';
export default {
  MarkdownImage,
  properiesFilteredUrl,
  comparatorsStringUrl,
  valueEditorUserUrl,
  valueEditorDateUrl,
  React
};