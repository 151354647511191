import React from "react";
import { string } from "prop-types";

const MarkdownImage = ({ src, alt, caption }) => {
  return (
    <figure>
      <img
        src={src}
        alt={alt}
        style={{
          position: "relative",
          maxWidth: "100%",
          height: "100%",
        }}
      />
      <figcaption>{caption}</figcaption>
    </figure>
  );
};

MarkdownImage.propTypes = {
  src: string,
  alt: string,
  caption: string,
};

export default MarkdownImage;
