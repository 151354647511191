export default class SwiperPagination {
  constructor(swiper) {
    this.swiper = swiper;
    this.steps = 0;
    this.timer = null;
    this.animate();

    swiper.on("slideChange", () => {
      if (swiper.autoplay.running) {
        swiper.pagination.bullets.forEach((bullet) => {
          this.reset(bullet.querySelector(".progress"));
        });
        clearInterval(this.timer);
        this.animate();
      }
    });

    swiper.on("autoplayStop", () => {
      clearInterval(this.timer);
    });
  }

  animate() {
    this.timer = setInterval(
      () =>
        this.setOffset(
          this.swiper.pagination.bullets[this.swiper.realIndex].querySelector(
            ".progress"
          )
        ),
      42
    );
  }

  setOffset(progress) {
    if (!progress) return;

    this.steps++;
    progress.style.strokeDashoffset = this.steps;
  }

  reset(progress) {
    this.steps = 0;
    progress.style.strokeDashoffset = -125;
  }

  static defaultBullet() {
    return function (index, className) {
      return '<span class="' + className + ' default"></span>';
    };
  }

  static autoplayBullet() {
    return function (index, className) {
      return `
                <div class="${className} autoplay">
                    <svg viewBox="0 0 42 42" preserveAspectRatio="xMidYMid meet">
                        <circle cx="21" cy="21" r="20" fill="transparent" stroke="rgba(0,0,0,0.5)"></circle>
                        <circle class="progress" cx="21" cy="21" r="20" fill="transparent" stroke="black" style="stroke-dashoffset:125; stroke-dasharray: 125 125"></circle>
                    </svg>
                    <span>${index + 1}</span>
                </div>
            `;
    };
  }
}
