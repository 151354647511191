import { hasClass, hexToRgb } from "./settings";

function generateStyles(colors) {
  let css = document.createElement("style");

  Object.keys(colors).forEach((key) => {
    Array.from(document.querySelectorAll(`.bg-custom-${key}`)).forEach(
      (element) => {
        if (hasClass(element, "bg-gradient")) {
          let rgb = hexToRgb(colors[key]);
          css.appendChild(
            document.createTextNode(
              `.bg-custom-${key}.bg-gradient:after {
                        background-image: linear-gradient(40deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 15%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0) 65%)
                    }`
            )
          );
        }

        if (hasClass(element, "float-teaser")) {
          css.appendChild(
            document.createTextNode(
              `.bg-custom-${key}.float-teaser:before, 
                    .bg-custom-${key} .float-teaser-body {
                        background-color: ${colors[key]}
                    }`
            )
          );
        }

        css.appendChild(
          document.createTextNode(
            `.bg-custom-${key} {
                    background-color: ${colors[key]}
                }`
          )
        );
      }
    );
  });

  document.getElementsByTagName("head")[0].appendChild(css);
}

export default generateStyles;
