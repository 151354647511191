export default class Parallax {
  constructor(element, method) {
    this.element = element;
    this.speed = 0.5;
    this.opacity = 1;
    this.offset = 1;
    this.method = method;
  }

  animate() {
    if (!this.element) return;

    this.attributes = {
      height: document.body.clientHeight,
      viewport: window.innerHeight,
      offset: window.pageYOffset,
      position: this.element.getBoundingClientRect(),
    };

    if (this.method === "translate") {
      this.updateOpacity().updateOffset();
    }

    if (this.method === "background") {
      this.updateOffset();
    }
  }

  updateOpacity() {
    let { height, offset } = this.attributes;

    this.opacity = (height - offset) / height;

    this.element.style.opacity = this.opacity;

    return this;
  }

  updateOffset() {
    let { height, viewport, offset, position } = this.attributes;

    this.offset = offset * this.speed;

    if (this.offset > height - viewport * 1.5) {
      this.offset = height - this.attributes.viewport * 1.5;
    }

    if (this.method === "translate") {
      this.element.style.transform = "translate3d(0, " + this.offset + "px, 0)";
    }

    if (this.method === "background") {
      let element = position.y + viewport - (offset - viewport);
      this.offset = 70 - (offset - element) / 100;
      this.element.style.backgroundPositionY = this.offset + "%";
    }

    return this;
  }
}
