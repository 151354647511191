import { breakpoints } from "./settings.js";

export default class VerticalOffset {
  constructor(elements) {
    this.minWidth = breakpoints.lg;
    this.elements = elements;

    this.handle(elements).listen();
  }

  handle(elements) {
    elements.forEach((element) => {
      element.style.cssText = "";
      element.style.marginTop = this.getOffset(element);
    });

    return this;
  }

  listen() {
    window.addEventListener("resize", () => {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => this.handle(this.elements), 100);
    });
  }

  getOffset(element) {
    let offset = "-60px";

    if (window.innerWidth < this.minWidth) {
      return offset;
    }

    if (element.dataset.offset) {
      let matches = element.dataset.offset.match(/^(\d+(?:\.\d+)?)(.*)$/);

      if (matches[2] === "px") {
        return -matches[1] + "px";
      }

      if (matches[2] === "%") {
        return -((element.clientHeight * matches[1]) / 100) + "px";
      }
    }

    if (element && element.dataset.offsetTarget) {
      const target = document.getElementById(element.dataset.offsetTarget);
      /* prevents errors when changing panges and viewport in close succession */
      if (target === null || target === undefined) return;

      return (
        -(
          element.getBoundingClientRect().top -
          target.getBoundingClientRect().top
        ) + "px"
      );
    }

    return offset;
  }
}
