import easings from "./easing.js";

export default function scrollToTop(duration = 200, easing = "linear") {
  const start = window.pageYOffset;
  const startTime = new Date().getTime();

  function handle() {
    const now = new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    window.scroll(0, Math.ceil(easings[easing](time) * (0 - start) + start));

    if (window.pageYOffset === 0) {
      return;
    }

    requestAnimationFrame(handle);
  }

  handle();
}
