import { breakpoints } from "./settings";

/**
 * Set the navbar height based on the
 * current scroll position
 * @return {void}
 */
export const toggleNavbar = (navbar) => {
  if (window.innerWidth <= breakpoints.md) {
    navbar.reset();
    return;
  }

  navbar.menu.element.style.display = "block";

  let scrollPosition = window.pageYOffset;

  if (scrollPosition > 100) {
    navbar.element.style.height = "72px";
    return;
  }

  navbar.element.style.cssText = "";
};

export default class Navbar {
  constructor(element) {
    this.element = element;
    this.button = element.querySelector("[data-toggle-control]");
    this.menu = new Menu(element.querySelector("[data-toggle-target]"));

    this.setup();
  }

  setup() {
    this.button.addEventListener("click", () =>
      this.handleState(this.menu.toggle())
    );
  }

  handleState(open) {
    if (open) {
      this.button.classList.add("open");
    } else {
      this.button.classList.remove("open");
    }
  }

  reset() {
    this.element.style.cssText = "";
  }
}

export class Menu {
  constructor(element) {
    this.element = element;
    this.isOpen = false;

    this.setActive(element);
  }

  toggle() {
    if (this.isOpen) {
      this.hide();
    } else {
      this.show();
    }

    return (this.isOpen = !this.isOpen);
  }

  show() {
    this.element.style.height = "100%";
    this.element.style.overflow = "auto";
  }

  hide() {
    this.element.style.height = "0%";
    this.element.style.overflow = "hidden";
  }

  setActive(element) {
    let pathname = window.location.pathname.split("/")[1];
    let menuitem = element.querySelector(`.navmenu-link[href="/${pathname}"]`);

    if (menuitem) {
      menuitem.classList.add("active");
    }
  }

  animate(callback) {
    this.frames++;
    window.requestAnimationFrame(callback);
  }
}
